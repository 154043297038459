import { useMutation } from "@tanstack/react-query"

import { API_DEFAULT } from "src/constants/minutApi"
import { IParadiseImpersonateResponse } from "src/data/paradise/paradiseImpersonate/types/paradiseImpersonateQueryTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function usePostImpersonate() {
  async function postImpersonate({
    userId,
    responseType,
  }: {
    userId: string
    responseType: "qr" | "access_token"
  }) {
    const response =
      await minutApiHttpClient.post<IParadiseImpersonateResponse>(
        `${API_DEFAULT}/impersonate`,
        {
          user_id: userId,
          response_type: responseType,
        }
      )

    return response.data
  }

  return useMutation({
    mutationFn: postImpersonate,
  })
}
