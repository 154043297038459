import { isParadiseProdEnv } from "src/constants/env"
import { webUrl } from "src/constants/hrefs"
import { usePostImpersonate } from "src/data/paradise/paradiseImpersonate/queries/paradiseImpersonateQueries"

export function useImpersonate() {
  const postImpersonate = usePostImpersonate()

  async function impersonateQR(userId: string) {
    postImpersonate.mutate({ userId, responseType: "qr" })
  }

  async function impersonate(userId: string) {
    postImpersonate.mutate(
      { userId, responseType: "access_token" },
      {
        onSuccess: (data) => {
          if (!data.access_token) {
            // Should we show an error banner if this happens?, it shouldn't happen
            return
          }

          const baseUrl = isParadiseProdEnv ? webUrl.production : webUrl.staging
          const params = new URLSearchParams({
            access_token: data.access_token,
            user_id: userId,
          })
          const url = `${baseUrl}?${params.toString()}`

          window.open(url)
        },
      }
    )
  }

  return {
    ...postImpersonate,
    impersonate,
    impersonateQR,
  }
}
